import Image from "next/image"
import Link from "next/link"
import React from 'react'
import AppsAndSocials from "./AppsAndSocials"
import Branding from "./Branding"
import { copyrightLinks } from "./constants"
import Contact from "./Contact"
import KnowMore from "./KnowMore"
import Links from "./Links"

const index = () => {
  return (
    <section className="tw-bg-primaryBlue tw-relative tw-text-white footerRevamp tw-pt-5 md:tw-pt-16 tw-pb-6">
      <div className="container tw-grid tw-grid-cols-1 tw-z-0">

        {/* TOP Section */}
        <div className="tw-grid footer-wrapper-column-template tw-gap-x-7 tw-gap-y-10 md:tw-mb-[60px]">
          {/* Left Section */}
          <div className="">
            <Branding />
            <AppsAndSocials />
            <Contact />
          </div>
          {/* Right Section */}
          <Links />
        </div>

        {/* BOTTOM Section */}
        {/* Waiting for compliance sign-off */}
        {/* <KnowMore /> */}

        {/* Disclaimer */}
        <div className="tw-mt-1 tw-pb-6 md:tw-pb-0 md:tw-pt-10 md:tw-border-t max-md:tw-border-b tw-border-white tw-border-opacity-[30%] tw-z-[2]">
          <p className="tw-text-xs tw-text-borderColor tw-m-0">
            Contact your Financial Advisor to know more about the scheme. AIF is made via private placement only. Investment in securities are subject to market and other risks. Achievement of stated objectives are also subject to market risk. The scheme does not guarantee either of the above. Please read the Private Placement Memorandum carefully before investing.
          </p>
        </div>

        {/* Copyright */}
        <div className="tw-mt-9 tw-flex max-md:tw-flex-col tw-justify-between tw-gap-x-7">
          <p className="tw-text-xs tw-text-footerWhite tw-font-normal">
            © Copyright {new Date().getFullYear()} Motilal Oswal Asset Management Company Pvt. Ltd.
          </p>
          <div className="max-md:tw-mt-3 tw-flex tw-items-center tw-gap-x-4 md:tw-gap-x-7 tw-flex-wrap">
            {copyrightLinks.map((item, i) => <Link key={i} href={item.link} title={item.title} className="tw-text-nowrap tw-text-sm max-md:tw-leading-6 md:tw-text-xs hover:md:tw-underline tw-text-footerText hover:tw-text-footerText">{item.title}</Link>)}
          </div>
        </div>
      </div>

    </section>
  )
}

export default index