import Image from "next/image"
import Link from "next/link"
import { distributorServices, importantLinksPartnerServices } from "./constants"

const DistributorServicesPopup = () => {
  return (
    <div className="menu-container tw-fixed tw-left-0 tw-top-[var(--top-for-menu-popup)] tw-w-full tw-z-[1000]">
      <div className='menu-popup container tw-flex tw-overflow-hidden tw-p-0'>
      <div className="tw-grid tw-grid-cols-[3fr_1fr] tw-w-full tw-gap-8 tw-bg-white tw-rounded-lg tw-shadow-md">

        <div className='tw-p-8'>
          <div className="tw-grid tw-grid-cols-2 tw-gap-y-6 tw-gap-x-8">
            {distributorServices.map((item, i) =>
              <div key={i} className="tw-flex tw-items-center tw-gap-4 tw-py-4">
                <Image src={item.image} alt={item.title} width={32} height={32} />
                <Link key={i} href={item.link} title={item.title} className="hover:tw-text-black tw-text-base tw-w-fit tw-font-medium" target={item.external ? "_blank" : "_self"}>{item.title}</Link>
              </div>
            )}
          </div>
        </div>

        <div className="tw-p-8 tw-bg-[#EEF5FD] tw-rounded-r-lg">
            <h4 className="tw-text-lg tw-font-semibold tw-uppercase tw-text-base tw-pb-4">Important Links</h4>
            <div className="tw-pt-5 tw-grid tw-gap-y-4 tw-border-t tw-border-greyBorder">
              {importantLinksPartnerServices.map((item, i) => (
                <Link key={i} href={item.link} title={item.title} className="tw-text-base tw-leading-5 hover:tw-underline" target={item.external ? "_blank" : "_self"} >
                  {item.title}
                </Link>
              ))}
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default DistributorServicesPopup